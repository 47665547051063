$primary: #3f278d;

body {
  font-weight: 500;
}
.get-started.active.card-header i {
  color: #000 !important;
}
.page-item.active .page-link {
  background-color: #000;
  border-color: #000;
}
.header-title {
  display: flex;
  justify-content: space-between;
}
h4.h4-is-center {
  text-align: center;
  width: 100%;
}
small,
.small {
  font-size: 90%;
}
i.mdi.permission.mdi-check-circle {
  color: $primary;
}
i.mdi.permission.mdi-checkbox-blank-circle {
  color: #808080fa;
}
.domain-item {
  display: flex;
  justify-content: space-between;
  padding: 6px 0px;
}
.domain-item button {
  width: 8%;
}

.domains-list.row div.card {
  border: solid 1px gray;
  box-shadow: 0 0 3px 0px;
}

#sidebar-menu ul li a {
  font-weight: bold;
}

table.react-bootstrap-table a.text-body {
  color: $primary !important;
}

select.custom-select {
  width: 100px;
}
label.search-label input {
  border-radius: 5px !important;
}

button.btn-rounded.search-icon-btn {
  background: unset;
  color: #8b909b;
  border: none;
}
.search-box.inline-button .search-icon-btn {
  top: 2.49px;
}

.btn-rounded {
  border-radius: 5px;
}

.pagination.pagination-rounded .page-link {
  border-radius: 5px !important;
}
a.text-body.font-weight-bold span {
  color: $primary;
}
a.text-secondary i,
a.text-danger i,
a.text-primary i {
  background: #f8f8fb;
  padding: 5px 5px;
  border-radius: 3px;
}
i.fas.fa-dollar-sign {
  padding: 5px 7px;
}
a.text-secondary i:hover,
a.text-danger i:hover,
a.text-primary i:hover {
  background: $primary;
  color: white;
}

.mm-active,
.mm-active i {
  color: #3f278d !important;
}
div#sidebar-menu li.mm-active {
  color: #ffffff !important;
  background: $primary;
}

div#sidebar-menu li.mm-active ul {
  background: white !important;
}
div#sidebar-menu li.mm-active ul li.mm-active,
div#sidebar-menu li.mm-active ul li.mm-active a {
  background: white !important;
  color: $primary !important;
}

div#sidebar-menu li.mm-active > a,
div#sidebar-menu li.mm-active > a > i {
  color: #ffffff !important;
}

.search-box button.btn-sm.btn-rounded.search-icon-btn:active {
  background-color: white;
  border: none;
  color: $primary;
}

button.btn-sm.btn-rounded.search-icon-btn.btn.btn-primary:active {
  border: 0 white;
  background: white;
}
button.btn-sm.btn-rounded.search-icon-btn.btn.btn-primary:hover i {
  color: $primary;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.welcome-board.row {
  background: white;
  border-radius: 5px;
  border: solid 1px #3cc390;
  padding: 1px;
}
div#daterange {
  width: 250px !important;
}

.label-content {
  b {
    margin-right: 5px;
  }
}

table.map-view-drivers {
  thead {
    display: none;
  }
}
.crypto_tab {
  display: flex;
  margin: 0px 0 20px 0px;

  border-radius: 50px;
  justify-content: space-between;
  background: #00000080;
  align-items: center;
  color: #fff;
}
.crypto_tab div {
  flex: 1 1 auto;
  border-radius: 50px;
  transition: 0.3s all;
}
.crypto_tab label {
  margin: 0;
  padding: 10px;
}

.activetab {
  border-radius: 50px;
  background: #000000e3;
}
